/* Container for the entire series detail view */
.seriesDetailContainer {
  background-color: #000;
  color: #F5F5DD;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  box-sizing: border-box;
 
}

/* Style for the back button */
.backButton {
  background-color: #fcca83;
  color: #bf6b46;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  align-self: flex-start;
  margin: 20px;
  margin-top: 60px; /* Adjust this value based on your navbar height */
  border-radius:5px;
}

.backButton:hover {
  background-color: #bf6b46;
  color: #fcca83;
}

/* Main content area */
.seriesDetail {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  
}

/* Content of the series detail view */
.seriesDetailContent {
  display: flex;
  flex-direction: column; /* Default layout for mobile */
  align-items: center;
  width: 100%;
  max-width: 1200px; /* Restrict max width for large screens */
  box-sizing: border-box;
}

.seriesMainContent {
  display: flex;
  flex-direction: column; /* Stack vertically by default for mobile */
  align-items: center;
  width: 100%;
  margin-top: 20px;
}

/* Style for the series poster image */
.seriesPoster {
  width: 100%;
  max-width: 300px; /* Adjust max-width as needed */
  height: auto;
  margin-bottom: 20px;
  border-radius: 8px; /* Add border-radius for rounded corners */
}

/* Style for the series overview */
.seriesOverview {
  font-size: 16px; /* Adjust font size as needed */
  line-height: 1.5; /* Improve readability */
 
  max-width: 1000px; /* Restrict max width for better readability */
  padding: 0 20px; /* Add horizontal padding */
  text-align: justify; /* Justify text for a clean layout */
}
.seriesInfoWrapper {
  display: flex;
  flex-direction: column; /* Default layout for mobile */
  align-items: center;
  width: 100%;
  max-width: 1200px;
  margin-bottom: 50px;
}
/* Wrapper for the season select dropdown */
.seasonSelectWrapper {
  width: 100%;
  max-width: 300px;
  margin-bottom: 20px; /* Add margin below the dropdown */
}

/* Style for the season select dropdown */
.seasonSelect {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  background-color: #222;
  color: #F5F5DD;
  border: 1px solid #444;
  border-radius: 5px;
}

/* Container for buttons */
.buttonGroup {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
}

.buttonGroup button {
  margin: 5px; /* Space out buttons */
}

/* Wrapper for the episodes section */
.episodes {
  color: #F5F5DD;
  width: 100%;
  max-width: 800px;
  margin-top: 20px;
  overflow: hidden; /* Hide overflow for smooth transition */
  transition: max-height 0.5s ease-out, padding 0.5s ease-out; /* Smooth transition */
}

/* Hidden state of episodes section */
.episodes.hidden {
  max-height: 0; /* Collapse the container */
  padding: 0; /* Remove padding when hidden */
}

/* Visible state of episodes section */
.episodes.visible {
  max-height: 3000px; /* Expand the container */
  padding: 20px; /* Add padding when visible */
}

/* Button to toggle episodes visibility */
.toggleEpisodesButton {
  background-color: #fcca83;
  color: #bf6b46;
  border: none;
  padding: 10px 60px;
  cursor: pointer;
  margin: 10px 0;
  border-radius: 5px;
}

.toggleEpisodesButton:hover {
  background-color: #bf6b46;
  color: #fcca83;
  
}

/* Table for listing episodes */
.episodeTable {
  width: 100%;
  border-collapse: separate; /* Change from collapse to separate to allow rounded corners */
  border-spacing: 0; /* Remove default spacing */
  margin-top: 10px;
  border-radius: 10px; /* Apply border-radius to the entire table */
  overflow: hidden; /* Clip child elements to the rounded corners */
}

/* Apply border-radius to the first and last cells in the header */
.episodeTable th:first-child {
  border-top-left-radius: 10px; /* Round top-left corner */
}
.episodeTable th:last-child {
  border-top-right-radius: 10px; /* Round top-right corner */
}

/* Apply border-radius to the first and last cells in the last row */
.episodeTable tr:last-child td:first-child {
  border-bottom-left-radius: 10px; /* Round bottom-left corner */
}
.episodeTable tr:last-child td:last-child {
  border-bottom-right-radius: 10px; /* Round bottom-right corner */
}
.episodeTable th,
.episodeTable td {
  padding: 8px;
  border: 1px solid #ddd;
}

.episodeTable th {
  background-color: #333;
}

.episodeTable tr:nth-child(even) {
  background-color: #222;
}

.episodeTable tr.selected {
  background-color: #bf6b46; /* Highlight the selected row with a distinct color */
  color: #000000; /* Ensure text color is readable */
}

.episodeButton {
  background-color: #fcca83;
  color: #bf6b46;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
}


.episodeButton:hover {
  background-color: #bf6b46;
  color: #F5F5DD;
}

/* Series information styling */
.seriesInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  margin-top: 20px;
}

/* Wrapper for the series information text */
.seriesInfoWrapper {
  display: flex;
  flex-direction: column; /* Align items vertically */
  align-items: center; /* Center items horizontally */
  width: 100%;

  margin-bottom:50px;
}

/* Style for the series text */
.seriesText {
  max-width: 100%; /* Ensure text area does not overflow */
  display: flex;
  flex-direction: column;
  align-items: center; /* Center text horizontally */
  text-align: center; /* Center text alignment */
  margin-bottom: 30px;
}

.seriesText h1 {
  font-size: 24px; /* Adjust font size as needed */
  margin: 0; /* Remove default margin */
}

/* Flexbox layout for large screens */
@media (min-width: 769px) {
  .seriesMainContent {
    flex-direction: row; /* Align items horizontally */
    align-items: flex-start; /* Align items to the top */
  }

  .seriesInfoWrapper {
    flex-direction: row; /* Align items horizontally */
    align-items: center; /* Align items to the top */
    justify-content: center; /* Center items within the container */
  }

  .seriesPoster {
    margin-right: 20px; /* Space between image and overview */
    margin-bottom: 0; /* Remove bottom margin on larger screens */
  }

  .seriesOverview {
    margin-top: 0; /* Remove top margin on larger screens */
    margin-left: 20px; /* Space between image and overview */
    align-self: center; /* Align the overview to the center of the flex item */
  }
}

/* Responsive styles for smaller screens */
@media (max-width: 768px) {
  .seriesPoster {
    max-width: 300px; /* Adjust max-width for smaller screens */
  }

  .seasonSelectWrapper {
    max-width: 100%;
  }

  .episodeTable {
    font-size: 14px;
  }

  .videoPlayer iframe {
    height: 300px; /* Adjust height for smaller screens */
  }

  .buttonGroup {
    flex-direction: column;
    align-items: center;
  }

  .seriesMainContent {
    flex-direction: column; /* Stack vertically on smaller screens */
    align-items: center;
  }

  .seriesPoster {
    margin-bottom: 20px; /* Space below the image on smaller screens */
  }

  .seriesOverview {
    margin-top: 0; /* Ensure overview is on top of the image */
    text-align: center; /* Center text on smaller screens */
  }
}
