/* MovieCard container */
.movie-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: #F5F5DD;
  cursor: pointer;
  margin-right: 10px;
  flex: 0 0 auto; /* Prevents the card from shrinking or growing unexpectedly */
  position: relative; /* Required for absolute positioning of overlay */
  width: 180px; /* Ensure a consistent width for all cards */
  transition: transform 450ms, opacity 450ms;
}

.movie-card:hover {
  transform: scale(1.02); /* Slightly enlarge the card on hover */
}

/* Image styling */
.movie-card-image-wrapper {
  position: relative;
}

.movie-card img {
  width: 100%; /* Ensure the image width matches the card width */
  height: auto; /* Maintain the aspect ratio */
  max-height: 300px; /* Uniform poster height */
  object-fit: cover; /* Ensure the image covers the entire container */
  border-radius: 12px;
  transition: opacity 450ms;
}

/* Information overlay */
.movie-card-info {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.8);
  color: #F5F5DD;
  padding: 10px;
  border-radius: 0 0 12px 12px;
  opacity: 0; /* Hide the overlay initially */
  transition: opacity 450ms;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.movie-card:hover .movie-card-info {
  opacity: 1; /* Show the overlay on hover */
}

.movie-card-title {
  font-size: 13px;
  font-weight: 500;
  color: #ffffff;
  overflow-wrap: break-word;
  white-space: normal;
  text-overflow: ellipsis;
}

.movie-card-details {
  font-size: 12px;
  color: #cccccc;
  margin-top: 4px;
  line-height: 1.2; /* Consistent line height for better readability */
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .movie-card {
    width: 120px; /* Consistent smaller card width for mobile screens */
  }

  .movie-card img {
    max-height: 200px; /* Smaller height for mobile screens */
  }

  .movie-card-title {
    font-size: 10px; /* Smaller title font size */
  }

  .movie-card-details {
    font-size: 10px; /* Smaller details font size */
  }
}

/* Placeholder styling for loading state */
.movie-card-placeholder {
  width: 180px; /* Consistent placeholder width */
  height: 300px; /* Match the size of your movie posters */
  background-color: #ccc;
  border-radius: 12px;
  margin-right: 10px;
  display: inline-block;
}

@media (max-width: 768px) {
  .movie-card-placeholder {
    width: 120px; /* Adjust placeholder width for mobile */
    height: 200px; /* Adjust placeholder height for mobile */
  }
}
