.category-filter {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.filter-toggle {
  padding: 10px 20px;
  border: none;
  background-color: #bf6b46; /* Dark background color */
  color: #fcca83; /* Bright blue text color */
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
  font-size: 16px;
  margin-bottom: 10px;
}

.filter-toggle:hover {
  background-color: #fcca83; /* Slightly darker blue on hover */
  color: #bf6b46;
}

.category-menu {
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(120px, 1fr)
  ); /* Responsive grid layout */
  gap: 10px; /* Space between buttons */
  width: 100%;
  max-width: 800px; /* Maximum width for the menu */
}

.category-button {
  padding: 10px;
  border: none;
  background-color: #fcca83 ; /* Dark background color */
  color: #bf6b46; /* Bright blue text color */
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s, box-shadow 0.3s;
  font-size: 14px; /* Slightly smaller font size */
  text-align: center; /* Center text horizontally */
  white-space: normal; /* Allow text to wrap if needed */
  overflow: hidden; /* Hide overflowing text */
  text-overflow: clip; /* Clip overflowing text */
}

.category-button.selected {
  background-color: #bf6b46; /* Bright blue background when selected */
  color: #fcca83; /* Dark text color when selected */
  transform: scale(1.06); /* Slightly larger when selected */
  box-shadow: 0 0 10px rgba(252, 202, 131, 0.7); /* Blue shadow effect */
}

.category-button:hover {
  background-color: #bf6b46; /* Slightly darker blue on hover */
  color: #fcca83;
  transform: scale(1.05); /* Slightly larger on hover */
}

.category-button:focus {
  outline: none;
  box-shadow: 0 0 10px rgba(252, 202, 131, 0.7); /* Blue shadow effect */
}
