.title {
  position: fixed;

  top: 0;
  left: 0;

  height: 100%;
  width: 100%;

  padding: 30px 60px;

  background-color: rgba(0, 0, 0, 0.5);

  overflow: auto;

  z-index: $title-index;
}

.title-container {
  position: relative;

  min-height: 100%;
  width: 100%;
  max-width: 900px;

  margin: 0 auto;

  background-color: $background-color;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);

  animation: fade_in 300ms linear forwards;

  overflow: hidden;
}

.title-close {
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;

  top: 20px;
  right: 20px;

  width: 40px;
  height: 40px;

  background-color: $background-color;
  border-radius: 50%;

  font-size: 25px;

  z-index: 1;

  cursor: pointer;
}

.title-backdrop {
  position: relative;

  width: 100%;

  aspect-ratio: 16/9;

  background-color: black;
  background-position: center;
  background-size: cover;

  &::after {
    content: '';

    position: absolute;
    left: 0;
    bottom: 0;

    height: 50%;
    width: 100%;

    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, $background-color 100%);
  }
}

.title-content {
  position: relative;

  margin-top: -250px;
  padding: 0 40px 40px;

  z-index: 1;
}

.title-logo {
  display: flex;
  align-items: flex-end;

  height: 150px;
  width: 100%;
  max-width: 50%;
}

.title-logo img {
  display: block;

  max-height: 100%;
  max-width: 100%;
}

.title-actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 15px;

  margin-top: 20px;
}

.title-actions .button {
  height: 44px;

  font-size: 16px;

  &:not(:first-child) {
    display: flex;
    align-items: center;
    justify-content: center;

    height: 44px;
    width: 44px;

    padding: 0;

    border-radius: 50%;
  }

  i {
    font-size: 18px;
  }
}

.title-grid {
  display: grid;
  grid-template-columns: 70% 30%;

  margin-top: 50px;
}

.title-col {
  width: 100%;

  padding-right: 20px;

  &:last-child {
    padding-right: 0;
  }
}

.title-tagline {
  font-size: 17px;
  font-weight: 500;
}

.title-meta {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 10px;

  margin-top: 5px;

  color: $text-two;

  font-size: 15px;
  font-weight: 500;
}

.title-description {
  margin-top: 20px;

  line-height: 1.4;

  font-size: 14px;
  font-weight: 400;
}

.title-list {
  display: flex;
  flex-wrap: wrap;

  line-height: 1.5;

  font-size: 14px;
  font-weight: 500;

  .head {
    margin-right: 5px;

    color: $text-three;
  }

  a {
    margin-right: 5px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.title-section {
  margin-top: 50px;
}

.title-section h3 {
  font-size: 22px;
  font-weight: 700;
}

.title-cards {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px 15px;

  margin-top: 20px;
}

.title-extend {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  position: relative;

  height: 1px;

  margin-top: 20px;

  &.active {
    margin-top: 30px;
    z-index: -1;
  }
}

.title-extend::before {
  content: '';

  position: absolute;
  left: -40px;
  bottom: -20px;

  height: 100px;
  width: calc(100% + 80px);

  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, $background-color 70%);

  z-index: 0;
}

.title-extend::after {
  content: '';

  position: absolute;

  left: 0;
  bottom: 0;

  width: 100%;
  height: 1px;

  background-color: rgba(#fff, 0.2);
}

.title-extend .button {
  position: relative;

  height: 44px;
  width: 44px;

  padding: 0;

  border-radius: 50%;

  font-size: 18px;

  z-index: 1;
}

.title-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.title-select {
  display: flex;
  align-items: center;

  min-width: 180px;
  margin-left: auto;
  padding: 12px 20px;

  color: #F5F5DD;
  background-color: #242424;
  border: 2px solid rgb(77, 77, 77);
  border-radius: 5px;
  outline: none;

  appearance: none;
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='10' fill='white'><polygon points='0,0 10,0 5,5'/></svg>");
  background-repeat: no-repeat;
  background-position-x: calc(100% - 10px);
  background-position-y: calc(50% + 4px);
  background-size: 14px;

  font-size: 16px;
  font-weight: 700;

  cursor: pointer;
}

.title-episodes {
  display: flex;
  flex-direction: column;

  margin-top: 20px;
}

.episode {
  display: flex;
  flex-direction: row;
  align-items: center;

  padding: 20px;

  border-bottom: 1px solid rgba(255, 255, 255, 0.1);

  cursor: pointer;
}

.episode-number {
  color: $text-two;

  min-width: 70px;

  font-size: 24px;
  font-weight: 500;

  text-align: center;
}

.episode-image {
  width: 100%;
  max-width: 130px;

  aspect-ratio: 16/9;

  border-radius: 5px;
}

.episode-text {
  width: 100%;

  margin-left: 20px;
}

.episode-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.episode-title {
  font-size: 15px;
  font-weight: 600;
}

.episode-runtime {
  margin-left: auto;

  font-size: 15px;
  font-weight: 500;
}

.episode-description {
  margin-top: 10px;

  color: $text-two;

  font-size: 14px;
}

@keyframes fade_in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@media (max-width: 1000px) {
  .title {
    padding: 30px 40px;
  }

  .title-actions .button {
    height: 40px;

    font-size: 16px;

    &:not(:first-child) {
      height: 40px;
      width: 40px;
    }
  }
}

@media (max-width: 700px) {
  .title {
    padding: 30px;
  }

  .title-cards {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 600px) {
  .title {
    padding: 0;
  }

  .title-container {
    border-radius: 0;
  }

  .title-content {
    margin-top: -150px;
  }

  .title-logo {
    height: auto;
    max-width: 70%;
  }

  .title-tagline {
    font-size: 16px;
  }

  .title-section {
    margin-top: 30px;
  }

  .title-grid {
    grid-template-columns: 100%;
    row-gap: 20px;
  }

  .title-col {
    width: 100%;

    padding-right: 0;
  }

  .title-cards {
    grid-template-columns: repeat(2, 1fr);
  }

  .episode {
    flex-direction: column;
  }

  .episode-number {
    display: none;
  }

  .episode-image {
    width: 100%;
    min-width: 200px;
    max-width: 60%;

    aspect-ratio: 16/9;

    border-radius: 5px;
  }

  .episode-text {
    width: 100%;
    margin-left: 0;
    margin-top: 20px;
  }
}
