.app {
  background-color: #111;
}
body {
  background-color: black;
  color: #F5F5DD; /* This will set the text color to white, making it readable on a black background */
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Arial, sans-serif; /* Optional: Set a default font */
}
