.movieDetailContainer {
  background-color: #000;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
}

.backButton {
  background-color: #fcca83;
  color: #bf6b46;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  align-self: flex-start;
  margin-bottom: 20px;
  border-radius:5px;
}

.backButton:hover {
  background-color: #bf6b46;
  color:#fcca83;
}

.movieDetail {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.videoPlayer {
  width: 100%;
  max-width: 800px;
  margin-bottom: 20px;
}

.movieDetailContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1200px;
}

.moviePoster {
  width: 100%;
  max-width: 400px;
  margin-bottom: 20px;
}

.movieInfo {
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 100%;
}

.movieOverview {
  margin: 20px 0;
}

.movieDetails {
  margin: 10px 0;
}

/* Styling for the video source buttons */
.videoSourceButtons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
}

.videoSourceButtons button {
  background-color: #fcca83;
  color: #bf6b46;
  border: none;
  padding: 10px 20px;
  margin: 10px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
  border-radius: 5px;
}

.videoSourceButtons button:hover {
  background-color: #bf6b46;
  color:#fcca83;
}
.videoSourceButtons button.selected {
  background-color: #bf6b46; /* Highlight color for selected button */
  border: 2px solid #fcca83; /* Optional: add a border to highlight */
  color: #fcca83; /* Optional: ensure text is readable on highlighted background */
}

@media (min-width: 768px) {
  .movieDetailContent {
    flex-direction: row;
    align-items: center;
  }

  .moviePoster {
    margin-right: 20px;
    margin-bottom: 0;
  }

  .movieInfo {
    align-items: flex-start;
    text-align: left;
  }
}
