.hero {
  position: relative;

  display: flex;
  flex-direction: column;

  height: 90vh;
  height: 90lvh;
  width: 100%;

  background-color: #000;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  overflow: hidden;

  z-index: 0;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: -50%;

    width: 200%;
    height: 100%;

    background: radial-gradient(farthest-side at 50% 0%, transparent 40%, $background-color);
  }
}

.hero-content {
  position: relative;

  margin: auto 60px;

  max-width: 500px;

  z-index: 1;
}

.hero-logo {
  display: block;

  max-width: 100%;
  max-height: 250px;
}

.hero-text {
  margin-top: 20px;

  font-size: 18px;

  line-height: 1.4;

  user-select: none;
}

.hero-actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 10px;

  position: relative;

  margin-top: 20px;

  z-index: 0;
}

@media (max-width: 1000px) {
  .hero-content {
    margin: auto 40px;
  }
}

@media (max-width: 700px) {
  .hero-content {
    max-width: 400px;
    margin: auto 30px;
  }

  .hero-logo {
    max-height: 150px;
  }

  .hero-text {
    line-height: 1.3;
    font-size: 16px;
  }
}

@media (max-width: 550px) {
  .hero-text {
    font-size: 15px;
  }
}
