/* General Row Styles */
.row {
  color: #F5F5DD;
  margin-left: 20px;
  position: relative;
}

.row-title {
  font-size: 20px;
  font-weight: 500;
}

/* Container for the slider and button */
.collection-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 15px -60px 0;
  padding: 0 60px;
  position: relative;
  overflow: hidden;
}

/* Flex container to align slider and button */
.collection-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  flex: 1; /* Allows slider to take available space */
}

/* Slider setup */
.collection-slider {
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow-x: auto;
  white-space: nowrap; /* Prevent wrapping */
  scrollbar-width: none; /* For Firefox */
  flex: 1; /* Ensures slider takes up available space */
  
}

/* Movie poster container */
.row_posterContainer {
  margin-right: 10px;
  flex: 0 0 auto;
  cursor: pointer;
  text-align: center;
  color: #F5F5DD;
}

/* Movie poster styling */
.row_poster {
  max-height: 300px;
  object-fit: contain;
  width: 100%;
  transition: transform 450ms, opacity 450ms;
  border-radius: 12px;
}

/* Hide scrollbar for Chrome, Safari, Opera */
.collection-slider::-webkit-scrollbar {
  display: none;
}

/* Poster hover effect */
.row_poster:hover {
  transform: scale(1.08);
  opacity: 1;
}

/* Large poster styling */
.row_posterLarge {
  max-height: 250px;
}

/* Large poster hover effect */
.row_posterLarge:hover {
  transform: scale(1.09);
  opacity: 1;
}

/* Poster info box */
.row_posterInfo {
  padding: 5px;
  margin-top: 5px;
  border-radius: 5px;
}

/* Poster title styling */
.row_posterTitle {
  font-size: 14px;
  margin: 0;
  color: #F5F5DD; 
  overflow-wrap: break-word; /* Ensures long words break and wrap */
  text-align: center; /* Center the text */
  max-width: 150px; /* Adjust this value as needed to fit your design */
  white-space: normal; /* Allow wrapping onto multiple lines */
}

/* Poster details styling */
.row_posterRuntime,
.row_posterEpisodes,
.row_posterStatus,
.row_posterAverageRuntime {
  font-size: 12px;
  margin: 0;
  color: #F5F5DD; 
}

/* Series info box */
.row_seriesInfo {
  display: flex;
  flex-direction: column;
}

/* Arrow buttons styling */
/* Arrow buttons styling */
.arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: #F5F5DD;
  width: 40px; /* Make the arrow buttons circular */
  height: 40px; /* Same width and height */
  border-radius: 50%; /* Circular shape */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 10;
  transition: background-color 300ms, transform 300ms, box-shadow 300ms;
}

/* Left and right arrow button positions */
.arrow-left {
  left: 10px; /* Adjust position if necessary */
}

.arrow-right {
  right: 10px; /* Adjust position if necessary */
}

/* Arrow hover effect */
.arrow:hover {
  background-color: rgba(0, 0, 0, 0.7);
 
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); /* Add a subtle shadow effect */
}

/* Arrow focus effect */
.arrow:focus {
  outline: none;
  background-color: rgba(0, 0, 0, 0.7);
}

/* "View All" button styling */
/* "View All" button styling */
.all-button {
  background-color: rgba(0, 0, 0, 0.7);
  color: #F5F5DD;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  text-align: center;
  transition: background-color 300ms, transform 300ms;
  margin-left: 20px; /* Space between the slider and button */
  align-self: center; /* Aligns the button to the middle vertically */
}

/* "View All" button hover effect */
.all-button:hover {
  background-color: rgba(0, 0, 0, 0.9);
  transform: scale(1.05);
}

/* "View All" button focus effect */
.all-button:focus {
  outline: none;
  background-color: rgba(0, 0, 0, 0.9);
}

/* Placeholder for loading or missing content */
.placeholder {
  width: 100%;
  height: 300px; /* Match the size of your movie posters */
  background-color: #ccc;
  border-radius: 12px;
  margin-right: 10px;
  display: inline-block;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .collection-slider {
    padding: 0 30px;
    overflow-x: auto;
  }

  .arrow {
    display: none;
  }

  .row_poster {
    max-height: 200px;
  }

  .all-button {
    font-size: 14px; /* Adjust font size for smaller screens */
    padding: 8px 16px; /* Adjust padding for smaller screens */
  }
}
