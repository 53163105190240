/* Banner.css */
.banner {
  position: relative;
  color: #f5f5dd;
  background-size: cover; /* Cover the entire container */
  background-position: center; /* Center the image */
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  height: 100vh; /* Full viewport height */
  
}

.banner_contents {
  position: absolute; /* Position the content absolutely within the banner */
  bottom: 30%; /* Align content to the bottom */
  left: 3%; /* Align content to the left */
  padding: 20px; /* Padding around content */
  width: 100%; /* Full width of the banner */
  max-width: 800px; /* Max width for large screens */
  box-sizing: border-box; /* Include padding in width calculation */
}

.banner_title {
  font-size: 2.5rem; /* Adjust font size for emphasis */
  font-weight: 800;
  color: #f5f5dd;
  padding-bottom: 0.5rem; /* Increase spacing below title */

  /* Add text shadow effect */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Horizontal, Vertical, Blur, Color */
}


.banner_description {
  width: 100%;
  max-width: 600px; /* Adjust max width for readability */
  line-height: 1.4; /* Adjust line height for readability */
  padding-top: 1rem;
  color: #f5f5dd;
  font-size: 1rem; /* Adjust font size for better visibility */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Horizontal, Vertical, Blur, Color */
}

.banner--fadeBottom {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 7.4rem;
  background-image: linear-gradient(
    180deg,
    transparent,
    rgba(37, 37, 37, 0.61),
    #111
  );
  pointer-events: none; /* Ensure it doesn't block interactions */
}

.banner_button {
  cursor: pointer;
  color: #F5F5DD;
  outline: none;
  border: none;
  font-weight: 700;
  border-radius: 0.2vw;
  padding: 0.5rem 2rem;
  margin-right: 1rem;
  background-color: rgba(51, 51, 51, 0.5);
  transition: all 0.2s ease;
}

.banner_button:hover {
  color: #000;
  background-color: #e6e6e6;
}
