$background-color: #121212;

$text-one: #ffffff;
$text-two: #c9c9c9;
$text-three: #9b9b9b;

$loading-index: 10;
$top-bar-index: 20;
$player-index: 30;
$title-index: 30;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
}

a {
  color: inherit;

  text-decoration: none;
}

body {
  color: $text-one;
  background-color: $background-color;

  height: 100vh;
}

#root {
  height: 100%;
}

@import 'button';
@import 'collection';
@import 'hero';
@import 'loading';
@import 'nav';
@import 'card';
@import 'page';
@import 'player';
@import 'title';
