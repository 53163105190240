.media-card {
  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;

  width: 100%;

  background-color: black;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  aspect-ratio: 2/3;

  overflow: hidden;

  transition: transform 200ms ease-in-out;

  user-select: none;

  &::after {
    content: '';
    display: block;

    position: absolute;

    height: 20%;
    width: 100%;

    bottom: 0;
    left: 0;

    opacity: 0;

    background: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.7));

    transition: opacity 200ms ease-in-out;

    z-index: 0;
  }

  &:hover,
  &.active {
    transform: scale(1.1);

    &::after {
      opacity: 1;
    }

    .media-card-actions {
      opacity: 1;
    }
  }
}

.media-card img {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100%;
  width: 100%;

  text-align: center;

  object-fit: cover;
}

.media-card-actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 10px;

  position: absolute;

  bottom: 15px;
  left: 15px;

  width: calc(100% - 30px);

  opacity: 0;

  transition: opacity 0.2s ease-in-out;

  z-index: 1;
}

.media-card-actions .button {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 35px;
  width: 35px;

  padding: 0;

  border-radius: 50%;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);

  &.secondary {
    box-shadow: 0 0 5px rgba(150, 150, 150, 0.4);
  }

  &.right {
    margin-left: auto;
  }
}

.media-card-actions .button i {
  height: 17px;
  width: 17px;

  font-size: 17px;
}

@media (max-width: 700px) {
  .media-card-actions .button {
    height: 30px;
    width: 30px;
  }

  .media-card-actions .button i {
    height: 15px;
    width: 15px;

    font-size: 15px;
  }
}
