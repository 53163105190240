.searchResults {
  padding: 20px;
  color: #f5f5dd;
}

.searchResults h2 {
  margin-bottom: 20px;
  text-align: center;
}

/* Grid container for results */
.resultsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr)); /* Adjusted min width */
  gap: 20px; /* Increased gap for better spacing */
  justify-items: center; /* Center items */
}

/* General styles for result items */
.resultItem {
  background-color: #1a1a1a;
  padding: 10px;
  border-radius: 8px;
  transition: transform 0.2s;
  cursor: pointer;
  max-width: 180px; /* Ensure a max width for consistency */
  width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.resultItem:hover {
  transform: scale(1.05);
}

.resultItem img {
  width: 100%;
  border-radius: 5px;
  object-fit: cover;
  height: 270px; /* Uniform height for images */
}

.resultItem p {
  margin-top: 10px;
  color: #f5f5dd;
  font-size: 14px;
  text-align: center;
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
}

/* Pagination styles */
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.pagination button {
  background-color: #fcca83;
  color: #bf6b46;
  border: none;
  padding: 10px 20px;
  margin: 0 10px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.pagination button:hover {
  background-color: #bf6b46;
  color: #fcca83;
}

.pagination button:disabled {
  background-color: #666;
  cursor: not-allowed;
}

.pagination span {
  color: #f5f5dd;
}

/* Responsive styles */
@media (max-width: 768px) {
  .resultsGrid {
    grid-template-columns: repeat(auto-fill, minmax(140px, 1fr)); /* Adjust grid for smaller screens */
    gap: 15px; /* Reduce gap on smaller screens */
  }

  .resultItem {
    max-width: 140px; /* Adjust max width for smaller screens */
  }

  .resultItem img {
    height: 210px; /* Adjust image height */
  }

  .pagination button {
    padding: 8px 15px; /* Reduce button size for mobile */
  }

  .pagination span {
    font-size: 14px; /* Adjust text size */
  }
}

/* Extra small screens */
@media (max-width: 480px) {
  .resultsGrid {
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr)); /* Further adjust grid for very small screens */
    gap: 10px; /* Reduce gap further */
  }

  .resultItem {
    max-width: 120px; /* Adjust max width */
  }

  .resultItem img {
    height: 180px; /* Further adjust image height */
  }

  .resultItem p {
    font-size: 12px; /* Reduce font size */
  }

  .pagination button {
    padding: 6px 12px; /* Further reduce button size */
  }

  .pagination span {
    font-size: 12px; /* Adjust text size */
  }
}
