/* Starry background styles */
.starry-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0; /* Ensure it stays behind other elements */
  pointer-events: none; /* Allow interactions with other elements */
}

/* Search page styles */
.search-page {
  position: relative; /* Needed to overlay on the starry background */
  z-index: 1; /* Ensure it's above the starry background */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: rgba(
    0,
    0,
    0,
    0.8
  ); /* Semi-transparent background to blend with the starry background */
  padding: 20px; /* Add some padding for smaller screens */
  box-sizing: border-box;
}

.logo-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.logo {
  width: 80%; /* Adjust width for responsiveness */
  max-width: 330px; /* Maximum width to maintain logo size on larger screens */
  height: auto; /* Maintain aspect ratio */
  margin-bottom: -30px;
}

.stingo {
  width: 70%; /* Adjust width for responsiveness */
  max-width: 250px; /* Maximum width to maintain image size on larger screens */
  opacity: 0.9;
}

h1 {
  color: #000000aa;
  font-size: 24px;
  margin: 0;
}

.search-container {
  display: flex;
  align-items: center;
  width: 100%; /* Full width on smaller screens */
  max-width: 600px; /* Maximum width to maintain form size on larger screens */
  flex-wrap: nowrap; /* Ensure input and button stay on the same line */
  margin-bottom: 10px; /* Add space below the search bar */
}

.search-input {
  flex-grow: 1;
  padding: 15px;
  border: none;
  border-radius: 5px 0 0 5px;
  font-size: 16px;
  background-color: #f5f5dd;
  outline: none;
  min-width: 0; /* Allow shrinking */
}

.search-input:focus {
  border: 1px solid #f5f5dd;
}

.search-button {
  background-color: #fcca83;
  border: none;
  padding: 15px;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
  flex-shrink: 0; /* Prevent shrinking */
}

.search-button svg {
  color: #000;
}

.view-site-button {
  background-color: #fcca83;
  border: none;
  padding: 15px;
  margin-left: 10px; /* Space between the search bar and the HomePage button */
  border-radius: 5px; /* Keep the same rounded style */
  cursor: pointer;
  font-size: 16px;
  display: flex;
  align-items: center; /* Center align icon and text */
}

.view-site-button svg {
  margin-right: 8px; /* Space between the icon and the text */
  color: #000;
}

/* Media query for mobile responsiveness */
@media (max-width: 768px) {
  .search-page {
    padding: 10px; /* Reduce padding for smaller screens */
    background-color: rgba(0, 0, 0, 0.8);
  }

  .logo-container {
    margin-bottom: 15px; /* Adjust spacing for smaller screens */
  }

  .logo {
    width: 60%; /* Reduce logo size on smaller screens */
  }

  .stingo {
    width: 60%; /* Reduce image size on smaller screens */
  }

  .search-container {
    width: 100%; /* Use full width */
    flex-wrap: wrap; /* Allow wrapping if necessary */
  }

  .search-input {
    padding: 10px; /* Reduce padding for smaller screens */
  }

  .search-button {
    padding: 10px; /* Reduce padding for smaller screens */
  }

  .view-site-button {
    padding: 10px; /* Reduce padding for smaller screens */
    margin-left: 0; /* Remove left margin for better wrapping on smaller screens */
    margin-top: 10px; /* Add space above the HomePage button when wrapped */
  }
}
