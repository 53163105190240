.HomePage {
  position: relative;
  z-index: 1;
  overflow: hidden; /* Prevents content from overflowing */
}
.collections.overlap {
  position: relative;
  margin-top: -20vh;
  z-index: 1;
}
.collections {
  display: flex;
  flex-direction: column;
  margin: 85px 0 0;
  padding: 0 60px 30px;
}
