/* Series Page Styles */
.series-page {
  padding: 20px;
  margin: 0 auto;
  max-width: 100%; /* Ensure the container does not exceed the viewport width */
  overflow-x: hidden; /* Hide any overflow to prevent horizontal scroll */
  box-sizing: border-box; /* Include padding and border in the element's total width */
}

.series-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 15px;
  margin-top: 20px;
}

.series-item {
  cursor: pointer;
  text-align: center;
  position: relative; /* Added for consistency with movies-page */
}

.series-poster {
  width: 100%;
  height: auto;
  border-radius: 8px;
  transition: transform 0.3s, box-shadow 0.3s; /* Match transition effects with movies page */
  aspect-ratio: 2 / 3; /* Ensures a consistent aspect ratio */
}

.series-item:hover .series-poster {
  transform: scale(1.05);
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2); /* Added shadow on hover */
}

.series-title {
  margin-top: 10px;
  font-size: 16px;
  font-weight: bold; /* Match font weight with movies page */
}

.series-quality { /* Added if there's a quality badge */
  background-color: rgba(0, 0, 0, 0.7);
  color: #f5f5dd;
  padding: 5px;
  border-radius: 5px;
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 14px;
}

/* Pagination container */
.pagination {
  margin-top: 20px;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-wrap: wrap; /* Allow buttons to wrap on smaller screens */
}

/* Pagination buttons */
.pagination-button {
  margin: 5px;
  padding: 8px 12px;
  border: none;
  background-color: #fcca83;
  color: #bf6b46;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s, box-shadow 0.3s;
  font-size: 14px;
  min-width: 60px; /* Ensure enough space for "Previous" and "Next" */
  max-width: none; /* Allow buttons to expand as needed */
  overflow: visible; /* Ensure text is not truncated */
  white-space: nowrap; /* Prevent text from wrapping */
}

/* Hover effect for pagination buttons */
.pagination-button:hover {
  background-color: #bf6b46;
  color: #f5f5dd;
  transform: scale(1.1);
}

/* Focus effect for pagination buttons */
.pagination-button:focus {
  outline: none;
  box-shadow: 0 0 10px rgba(252, 202, 131, 0.7);
}

/* Disabled state for pagination buttons */
.pagination-button.disabled,
.pagination-button:disabled {
  background-color: #333;
  color: #777;
  cursor: not-allowed;
}

/* Active state for pagination buttons */
.pagination-button.active {
  background-color: #bf6b46;
  color: #fcca83;
}

/* Responsive adjustments for mobile */
@media (max-width: 600px) {
  /* Reduce padding and font size for smaller screens */
  .pagination-button {
    padding: 6px 8px;
    font-size: 10px;
    min-width: 50px; /* Slightly reduce min-width for mobile */
  }

  /* Adjust margin to fit more buttons in a row */
  .pagination-button {
    margin: 3px;
  }

  /* Limit maximum width for buttons to prevent overflow */
}
